import React, { FC } from 'react'
import styled from 'styled-components'

import SEO from '../components/seo'
import Navigation from '../components/navigation'
import { StaticImage } from 'gatsby-plugin-image'

const HeroSection = styled.div`
	position: relative;
	width: 100%;
	background-color: ${p => p.theme.colors.yves};
	color: ${p => p.theme.colors.silver};
`

const Content = styled.div`
	max-width: 1024px;
	height: 100%;
	margin: 0 auto;
	padding-left: 2rem;
	padding-right: 2rem;

	h1 {
		margin-top: 0;
		margin-bottom: 1rem;
		font-weight: normal;
	}

	section {
		padding: 2rem 0;
	}

	h1 {
		padding-top: 2rem;
		margin-bottom: 1rem;
	}
`

const ImageRow = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	margin: 0 -0.5rem;
	@media only screen and (max-width: 720px) {
		flex-wrap: wrap;
	}
`

const Image = styled.div`
	flex: 1 1 50%;
	padding: 0.5rem;
`

const SchedulePage: FC<{}> = () => (
	<>
		<SEO title="Schedule" />
		<HeroSection>
			<Content>
				<Navigation />
				<h1>Program</h1>
				<h3>Thursday</h3>
				<p>
					For those of you keen on exploring Satu Mare and it's surroundings and
					spending a bit more time partying with us, yes, yes, yes!
				</p>
				<p>
					We recommend you fly to Debrecen on Wednesday night and sleep in on
					Thursday after a late arrival. Enjoy the day at your own pace. Let us
					know if you want to take a tennis class, go to the thermal baths or
					hop on a bike and cycle all the way to the Hungarian border. Or just
					WFSM if you're a busy bee.
				</p>
				<p>
					In the evening we can explore Satu Mare's city center, go for a pizza
					or a wine tasting of the local and neighbouring Hungarian wines. We
					can hit the clubs for those who are keen to practice their dancning
					moves and have a valid reason for a late night Satu Mare shawarma.
				</p>
				<h3>Friday</h3>
				<ImageRow>
					<Image>
						<StaticImage
							src="../images/cimitirul_vesel.jpeg"
							alt="Corvin Castle"
						/>
					</Image>
					<Image>
						<StaticImage
							src="../images/muzeul_tarii_oasului.jpeg"
							alt="Sibiu rooftops"
						/>
					</Image>
				</ImageRow>
				<p style={{ opacity: 0.4 }}>
					Source: Primaria Negresti Oas, agefotostock.com
				</p>
				<p>
					For those of you keen to explore some of the real Transylvania, we've
					organised a day trip into Oaș County (pronounced wash). This is one of
					the areas of Romania with the best preserved cultural and folkloric
					traditions, crafts, and down to earth country living.
				</p>
				<p>
					We will start fairly early and depart after breakfast in the morning.
					A bus will take us to the edge of Satu Mare and Baia Mare counties.
					Here we will visit one of Romania's most famous sites, Sãpanța's Merry
					Cemetery. The locals in this area are world famous for working wood,
					as sculptors, carpenters and joiners. While they mostly apply their
					skills to insanely tall wooden churches and beautifully carved wooden
					gates, after consuming the local plum brandy, palinca, they've strayed
					into more hilarious exploits. Wooden headstones. And in their revelry
					they've painted the wood with brigh colored hues, and sent their
					friends to the afterlife with hilarious poems that read like short
					comedy roasts.
				</p>
				<p>
					People from Oaș County are renowned in Romania for the pride they have
					in their roots, ancestry and customs. To see all these things in once
					place we'll visit the Tara Oasului Museum, where you can see a fully
					preserved village with houses, mill, church and other buildings
					typical of a local village. Over a big feast of a lunch, locals will
					show off the local traditional clothing, food, songs, dances and of
					course, pãlinca, the local plum brandy Transylvania is famous for.
				</p>
				<h3>Saturday</h3>
				<p>
					The big day is upon us! Spend the morning relaxing and having a nice
					breakfast and then start getting ready for the wedding ceremony and
					party. The mini buses or taxi will pick you up from your accomodation
					at noon and they'll take you to Carei. The trip takes 30 minutes.
				</p>
				<p>
					The day begins with the Orthodox wedding ceremony at the Central
					Church in Carei at 1PM. The priest will officiate the religious
					ceremony, where Emil and Laura, accompanied by their wedding
					godparents will say their vows and exchange rings. After the religious
					ceremony you'll throw some rice at the newly weds as they come out and
					we'll take some group pics at the church.
				</p>
				<p>
					From the church, a very short walk to The Castle. Our fantastic venue
					for the day, the Károlyi Castle, surrounded by the beautiful English
					gardens awaits us.
				</p>
				<p>
					The party starts around 3PM and will last until 2AM. Have fun, pace
					yourself for a lot of Romanian food and wine. Be ready for some great
					tunes, good coffee and smashing cocktails. We're expecting a lot of
					dancing, boys and girls, so make sure you've got some backup shoes.
					When you want to call it a night a taxi will whisk you off back to
					Satu Mare.
				</p>
				<h3>Sunday</h3>
				<p>
					Have a lie in after all the fun of the previous night, but not too
					much! You're invited for the Bride's Breakfast. An amazing tradition
					where we all meet at the bride's parent's house. Cristi and Anca will
					serve some hangover busting ciorbã, we'll crack some jokes and say our
					farewells.
				</p>

				<br />
			</Content>
		</HeroSection>
	</>
)

export default SchedulePage
